<template>
  <section :class="`wrapper2`" :style="{ backgroundColor: solutionData.color }">
    <div class="wrapper2-data">
      <div class="content">
        <div class="wrapper2-data-texts-solutions">
          <img :src="solutionData.logo" class="logo-list" alt="logo-list" />
          <div v-html="solutionData.description"></div>
        </div>
        <div class="wrapper2-data-points" v-html="solutionData.body"></div>
      </div>
      <button class="fixed white" @click="changeState">
        <span class="close" :class="solutionData.name">&times;</span>
        <span class="btn-back">{{ $t("app.back") }}</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SolutionsWrapper2',
  props: {
    solutionData: {
      type: Object,
      required: true
    }
  },
  mounted () {
    // alert(window.screen.height + ' ' + window.screen.width)
    if (navigator.userAgent.includes('iPhone')) {
      document.querySelector('.fixed').style.bottom = '-18%'
    }
  },
  methods: {
    changeState () {
      this.$emit('close-solution-detail')
    }
  }
}
</script>
<style>
.content {
  color: #fff;
}
.wrapper2-data .wrapper2-data-texts-solutions p, .wrapper2-data .wrapper2-data-texts-solutions li {
  font-size: 17px;
  font-family: "gilroy", sans-serif;
  font-weight: 100 !important;
}
@media (max-width: 768px) {
  .wrapper2-data .wrapper2-data-texts-solutions p, .wrapper2-data .wrapper2-data-texts-solutions li {
    font-size: 17px;
    font-family: "gilroy", sans-serif;
  }
}
</style>
<style lang="scss" scoped>
.ShowWrapper2 {
  display: flex !important;
  overflow-y: hidden;
}
.wrapper2 {
  /* display: flex; */
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.wrapper2-data {
  width: 100%;
  display: flex;
  height: 95vh;
  overflow-y: scroll;
  flex-direction: column;
  box-sizing: border-box;
  padding: 50px 140px 0 70px;
  .wrapper2-data-texts-solutions {
    h1 {
      font-size: 30px;
      color: #fff;
      margin-bottom: 25px;
    }
    p {
      font-size: 17px;
      font-family: "gilroy", sans-serif;
      font-weight: 100 !important;
    }
    li {
      font-family: "gilroy", sans-serif;
    }
  }
  button {
    margin: 25px 0;
    border: 1px solid #000;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    width: 130px;
    height: 32px;
    outline: none;
    position: fixed;
    bottom: 10px;
    cursor: pointer;
    flex-direction: row-reverse;
    img {
      width: 20px;
      height: 20px;
    }
    .close {
      margin-top: -2px !important;
    }
    span {
      font-size: 16px;
      padding-right: 15px;
    }
  }
  h1 {
    font-size: 35px;
    color: #fff;
    margin-bottom: 25px;
  }
  p {
    font-size: 17px;
    font-family: "Lato", sans-serif;
    font-weight: 100 !important;
    color: #fff;
  }
}
.wrapper2-data-points {
  padding: 25px 0 0 50px;
  margin-bottom: 80px;/*Adicionado recentemente*/
  ul {
    list-style: circle;
    li {
      color: #fff !important;
      font-size: 16px !important;
      font-family: "gilroy", sans-serif;
      font-weight: 100 !important;
    }
  }
}
.wrapper2-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.26s linear;
}

@media (max-width: 768px) {
  .wrapper2-data {
    width: 100% !important;
    padding: 0;
    padding-bottom: 60px;
    .wrapper2-data-texts-solutions {
      padding: 25px 15px 0 15px;
      h1 {
        font-size: 30px;
        color: #fff;
        margin-bottom: 25px;
        font-family: "gilroy", sans-serif;
      }
      p {
        font-size: 17px;
        font-family: "gilroy", sans-serif;
      }
      li {
        font-family: "gilroy", sans-serif;
      }
    }
    button {
      margin: 25px 0 25px 25px;
      border: 1px solid #000;
      border-radius: 25px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      //width: 70px;
      height: 25px;
      position: unset !important;
      bottom: unset !important;
      outline: none;
      cursor: pointer;
      span {
        width: 15px;
        height: 15px;
      }
      .btn-back {
        margin-top: -1px;
      }
      .close {
        margin: 0 0 0 15px !important;
      }
      span {
        font-size: 12px;
        padding-left: 5px;
        margin-top: 4px;
        margin-right: 6px;
      }
    }
  }
  .content {
    overflow: hidden;
    height: 64%;
    padding: 0 15px 0 15px;
    overflow-y: scroll;
  }

  .logo-list {
    width: 100% !important;
  }
}

/** iPhone X */
@media (width: 375px) and (height: 812px) {
  .fixed {
    bottom: 0 !important;
    // position: fixed !important;
  }
}
.logo-list {
  width: 200px;
  height: 100px;
  margin-bottom: 20px;
}

.white {
  color: #fff;
  border-color: #fff !important;
}
</style>
<style lang="scss">
img.logo-list {
  height: 33px !important;
}
@media (max-width: 768px) {
  .wrapper2-data-points {
    ul {
      li {
        color: #fff !important;
        font-size: 17px !important;
      }
    }
  }
  .close {
    height: 15px !important;
    width: 15px !important;
    margin: -1px 0 0 9px !important;
  }
}
.wrapper2-data-points {
  ul {
    list-style: circle;
    ul {
      list-style: disc;
      padding-left: 30px;
    }
    li {
      color: #fff !important;
      font-size: 16px;
      font-size: 16px !important;
      font-family: "gilroy", sans-serif;
      font-weight: 100 !important;
      padding: 10px 0;
      p {
        padding: 7px 0 0 20px;
        font-size: 16px !important;
        font-family: "gilroy", sans-serif;
        font-weight: 100 !important;
      }
    }
  }
}

.close {
  background: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 2px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}
.contact-title {
  font-size: 18px;
}
.wrapper2-data-points {
  /*padding-top: 10px !important;*/
}
@media (min-width: 1200px) {
  .contact-title {
    font-size: 24px;
  }
}
</style>
